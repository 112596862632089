import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> I'm Mohid Kazi.
        </BigTitle>
        <Subtitle>I'm a FULL Stack Web Developer who loves Javascript & Manga & Memes & Coffee.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Timeswappers"
            link="https://timeswappers.com"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            Website i am currently working on for my company
          </ProjectCard>
          <ProjectCard
            title="Resume"
            link="javascript:void()"
            bg="linear-gradient(to right, #852D91 0%, #A3A1FF 30%, #F24645 100%)"
          >
            unavailable
          </ProjectCard>
          <ProjectCard
            title="project1"
            link=""
            bg="linear-gradient(to right, #29323c 0%, #485563 35%, #2b5876 65%, #4e4376 100%)"
          >
            unavailable
          </ProjectCard>
          <ProjectCard
            title="Project2"
            link=""
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            unavailable
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="John Doe" />
          <AboutSub>
            You can also call me jasonTodd, as in the RedHood, a vigilante anti-hero who is a member of the Batman Family and the Outlaws. Trained by the best Jedi, with a hope to become the next Hokage and find the One Piece
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          You know the way you feel when you see a picture of two otters holding hands? That's how you're gonna feel
          every day. My mother cried the day I was born because she knew she’d never be prettier than me. You should
          make me your campaign manager. I was born for politics. I have great hair and I love lying. Captain? The kids
          want to know where Paulie the Pigeon is. I told them he got sucked up into an airplane engine, is that all
          right?
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say <a href="mailto:mohidkazix@gmail.com">Hi</a> or find me on other platforms:{' '}
            <a href="https://www.twitter.com/mohidkazi1">Twitter</a> &{' '}
            <a href="https://www.instagram.com/mohidkazi_">Instagram</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2019. &#60; &#62; by{' '}
          <a href="https://mohidkazi.github.io">MohidKazi</a>.
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
